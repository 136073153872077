import React, { useState, useEffect } from 'react'
import './Profile.css'
import Warnung from './Warnung'
import defaultAvatar from '../images/example_image.png'
import Section from './Section'
import MessageCreate from './MessageCreate'
import AddReview from './AddReview'
import { differenceInYears, differenceInDays } from 'date-fns';

import useAxiosPrivate from '../hooks/useAxiosPrivate'
import bin from '../pages/HPforPublic/HPimages/bin.png'

const Profile = ({ selectedUserId, setPopupWindowOnn, onUpdateChangesSuccess, onSuccessfulDelete }) => {

  const axiosPrivate = useAxiosPrivate()
  const [currentUser, setCurrentUser] = useState({})
  const [reviews, setReviews] = useState([])
  const [error, setError] = useState('')
  const [deleted, setDeleted] = useState('')
  const [selectedRoles, setSelectedRoles] = useState([])

  const currentDate = new Date();
  const birthDate = new Date(currentUser.birthDate)
  const createdAt = new Date(currentUser.createdAt)
  const [experiencePeriod, setExperiencePeriod] = useState(0)

  // good code 

  // const handleDeleteReview = async (reviewId) => {
  //   try {
  //     await axiosPrivate.delete(`${process.env.REACT_APP_BACKEND_URL}/api/reviews/${reviewId}`)
  //     setDeleted('The comment is deleted now!')
  //     console.log("reviewId", reviewId)

  //   } catch (error) {
  //     setError('Something went wrong: maybe server is down?')
  //   }
  // }

  const handleDeleteReview = async (reviewId) => {
    try {
      const confirmDelete = window.confirm('Are you sure you want to delete this comment?');

      if (confirmDelete) {
        await axiosPrivate.delete(`${process.env.REACT_APP_BACKEND_URL}/api/reviews/${reviewId}`);
        setDeleted('The comment is deleted now!');
        // console.log("reviewId", reviewId);
        onSuccessfulDelete();

        setCounter((prevCounter) => prevCounter + 1);
      }
    } catch (error) {
      setError('Something went wrong: maybe the server is down?');
    }
  };


  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data } = await axiosPrivate.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/users/${selectedUserId}`
        )
        setCurrentUser(data)
        setSelectedRoles(Object.keys(data.role))
      } catch (error) { }
    }
    fetchUser()
  }, [])

  // re-rendering counter when a new note is added
  const [counter, setCounter] = useState(0);
  const triggerRerenderReviews = () => {
    setCounter(counter + 1);
  };

  useEffect(() => {
    const fetchUserReviews = async () => {
      try {
        const { data } = await axiosPrivate.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/reviews/${selectedUserId}`
        )

        setReviews(data)

      } catch (error) {
        console.log(error)
      }
    }
    fetchUserReviews()
  }, [counter])


  useEffect(() => {
    const period = differenceInDays(currentDate, createdAt)

    if (period < 365) {
      setExperiencePeriod(`${period} days`)
    } else {
      setExperiencePeriod(`${period} years`)
    }
  }, [currentUser])


  const [submitted, setSubmitted] = useState(false)

  const submitUpdate = async () => {
    try {
      await axiosPrivate.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/update-by-admin/${selectedUserId}`,
        currentUser
      )
      setSubmitted('You successfully updated the changes!')

      setTimeout(() => {
        setSubmitted(false);
      }, 6000);

      onUpdateChangesSuccess();

    } catch (error) {
      setError(error)
    }
  }

  const avatarSrc = currentUser.avatar || defaultAvatar

  const roleMap = {
    Mentor: 1111,
    Student: 2222,
    Admin: 3333,
    Coach: 4444,
    Encourager: 5555,
    Writer: 6666,
    Moderator: 7777,
  }

  const handleSelectChange = (event) => {
    const selectedOptions = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    setSelectedRoles(selectedOptions);
  };

  useEffect(() => {
    const updatedRoles = selectedRoles.reduce((acc, role) => {
      acc[role] = roleMap[role];
      return acc;
    }, {});

    setCurrentUser((prev) => ({ ...prev, role: updatedRoles }));
  }, [selectedRoles]);

  // useEffect(() => { }, [currentUser])



  return (
    <>
      <main className='profile-case '>
        {submitted &&
          <div className='congrats'
            onClick={() => setSubmitted(false)}>
            <div className='congr_sub'>
              {/* <h4>Congratulations: </h4> */}
              <p> {submitted} </p>
              <div className="spacer20px"></div>
              <p>The changes will appear after the refresh of your page.</p>
              <div className="spacer20px"></div>
              <p>This message will disappear now!</p>
            </div>
          </div>
        }

        <div className='flex_it'>
          <div className='profile_info'>
            <div className='upper_part'>
              <div className='profile_name nick'>
                <p> <span className='list'> Full name: </span> </p>
                <p> {currentUser.nick} </p>
              </div>
              <div className='profile_name searchN'>
                <p><span className='list'>search name:</span></p>
                <p className='searchName'>{currentUser.searchName} </p>
              </div>
              <div className='other_details'>
                <div className="flexing_it">
                  <p className='profile_occupation'>
                    <span className='list'>occupation:</span>
                  </p>
                  <p className='profile_occupation'>
                    {currentUser.occupation}
                  </p>
                </div>
                <div className='flexing_it'>
                  <p className='profile_age'>
                    <span className='list'> his/her age: </span>
                  </p>
                  <p className='profile_age'>
                    {differenceInYears(currentDate, birthDate)} years old
                  </p>
                </div>
                <div className='profile_location flexing_it'>
                  <p>
                    <span className='list'>location: </span>
                  </p>
                  <p> {currentUser.location} </p>
                </div>
              </div>

              <div className='bio'>
                <h4>
                  Biography
                </h4>
              </div>
            </div>
            <div className='lower_part'>
              <div className='profile_self_note'>
                <p>
                  {currentUser.biography ||
                    'Admins do not write biographies. Also, their accounts do not change  roles or status or level. Maximum you can do is to remove them from the platform. They have to use a different login to use the main app.'}
                </p>
              </div>
            </div>
          </div>
          <Section />
        </div>

        <div className='another_container'>
          <div className='profile_left_panel'>
            <div className='left_wing'>
              <div className="add_roles">
                <p>Add roles:</p>
              </div>

              <div className={currentUser.role && currentUser.role.Admin !== undefined ? 'select_here disabled' : 'select_here'}>
                <select
                  id='multiSelect'
                  multiple
                  value={selectedRoles}
                  onChange={handleSelectChange}
                  disabled={currentUser.role && currentUser.role.Admin !== undefined}
                >
                  <option value='Mentor'>mentor</option>
                  <option value='Coach'>coach</option>
                  <option value='Encourager'>encourager</option>
                  <option value='Student'>student</option>
                  <option value='Writer'>writer</option>
                  <option value='Moderator'>moderator</option>
                </select>
              </div>

            </div>

            <div className='profile_rows right_wing'>
              <div className='profile-role'>
                <div className='profile_role_div'>
                  <p>Current role(s): <span className='roles_added'> {selectedRoles.join(', ')}</span></p>
                </div>
              </div>
              <div className='profile-experience'>
                <div className='profile_experience_div'>
                  <p>
                    Experience on ODP: {experiencePeriod}
                  </p>
                </div>
              </div>
              <div className="flex_it_also">
                <div className='profile-activity'>
                  <div className={currentUser.role && currentUser.role.Admin !== undefined ? 'Status_of_activity' : 'Status_of_activity'}>
                    <label>Stat:</label>
                    <select
                      name='activity'
                      disabled={currentUser.role && currentUser.role.Admin !== undefined}
                      value={currentUser.activity}
                      onChange={e => setCurrentUser({ ...currentUser, [e.target.name]: e.target.value })}
                    >
                      <option value='active'>active</option>
                      <option value='frozen'>frozen</option>
                    </select>
                  </div>
                </div>
                <div className='profile-level'>

                  <label>Level:</label>
                  <select
                    name='securityLevel'
                    // disabled={currentUser.role.Admin !== undefined}
                    onChange={e => setCurrentUser({ ...currentUser, [e.target.name]: e.target.value })}
                    disabled={currentUser.role && currentUser.role.Admin !== undefined}
                    value={currentUser.securityLevel}
                  >
                    <option value='high'>high</option>
                    <option value='low'>low</option>
                  </select>
                </div>

              </div>
              <div className='profile-connections'>
                <div>
                  <p>
                    Get more info
                  </p>
                </div>


                <MessageCreate titleNomi="Message" className={'message'} recipient={currentUser.nick} recipientEmail={currentUser.email} />

              </div>
              <div className="flexing_this_too">
                <button className='update_this_user' onClick={submitUpdate}>Update changes</button>

                <main className="for_delete">
                  <Warnung
                    selectedUserId={selectedUserId}
                    setPopupWindowOnn={setPopupWindowOnn}
                    onSuccessfulDelete={onSuccessfulDelete}
                  />
                </main>
              </div>
            </div>
          </div>

          <div className='profile_right_panel'>
            <div className='comments_by_others'>
              <h4>Comments by others:</h4>
            </div>
            <AddReview titleNomi="add a note" className={'add_comment'} currentUser={currentUser}
              onNewReviewPostSuccess={triggerRerenderReviews} />

            <div className='second_note'>
              <div className='notesByOthers'>

                <main className="notes_container">
                  {reviews.map(element => {

                    const createdAtDate = new Date(element?.createdAt);

                    const options = { day: 'numeric', month: 'long', year: 'numeric' };
                    const formattedCreatedAt = createdAtDate.toLocaleDateString('en-GB', options);

                    return (
                      <article key={element._id}>
                        <p>{element?.content}</p>
                        <p className='timing'>{element?.author?.nick} <span >{formattedCreatedAt}</span></p>
                        <button
                          title='remove the note'
                          className='remove_comment'
                          onClick={() => {
                            handleDeleteReview(element._id)
                          }}
                        >
                          <img src={bin} alt="" />
                        </button>
                      </article>
                    )
                  })}
                </main>

              </div>
            </div>
          </div>
        </div>
      </main >
    </>
  )
}

export default Profile