import React, { useState, useEffect } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { differenceInYears } from 'date-fns';
import Profile from './Profile'
import './UserList.css'


const UserList = ({ role }) => {
  const axiosPrivate = useAxiosPrivate()
  const [users, setUsers] = useState([])
  const [error, setError] = useState('')


  const [counter, setCounter] = useState(0);
  const triggerRerenderProfiles = () => {
    setCounter(counter + 1);
    // console.log("counter1 for updateChanges", counter);
  };

  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const { data } = await axiosPrivate.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/users?role=${role}`
        )
        setUsers(data)
      } catch (error) {
        setError(error)
      }
    }
    fetchAdmins()
  }, [counter])

  useEffect(() => {
  }, [users])

  const [selectedUserId, setSelectedUserId] = useState('')
  const [isPopupWindowOnn, setPopupWindowOnn] = useState(false)

  const selectedUser = users.find((user) => user._id === selectedUserId);

  return (
    <div className='admin-jsx'>
      <div className='spacer10px'></div>
      {error && <p>{error}</p>}
      <table>
        <tbody>
          {
            users?.map((user, index) => {
              const age = differenceInYears(new Date(), new Date(user.birthDate));

              return (
                <tr
                  key={user._id}
                  title='click to see more...'
                  onClick={() => {
                    setPopupWindowOnn(true)
                    setSelectedUserId(user._id)
                  }}
                >
                  <td className='dashboard-ordinals'>{index + 1}</td>

                  <td className='dashboard-names'>{user.nick}</td>
                  <td className='dashboard-searchName'> {user.searchName}</td>

                  <td className='dashboard-age'>Age: {age} years</td>
                  <td className='dashboard-securityLevel'>Level: {user.securityLevel}</td>
                  <td className='dashboard-activity'>Status: {user.activity}</td>
                  <td className='dashboard-occupation'>Work: {user.occupation}</td>
                  {/* <td className='dashboard-location'>Geo: {user.location}</td> */}
                </tr>
              )
            })
          }
        </tbody>
      </table>
      {isPopupWindowOnn && selectedUser && (
        <div className='profile-case-container'>
          <main className=''>
            <div className='all_kind_of_buttons'>
              <div className="for_click"
                onClick={() => setPopupWindowOnn(false)}
              ></div>
              <button
                title='close this profile case'
                className='close_profile_case'
                onClick={() => setPopupWindowOnn(false)}
              >
                &times;
              </button>
            </div>

            <Profile
              setPopupWindowOnn={setPopupWindowOnn}
              selectedUserId={selectedUserId}
              onUpdateChangesSuccess={triggerRerenderProfiles}
              onSuccessfulDelete={triggerRerenderProfiles}
            />
          </main>


        </div>
      )}
    </div>
  )
}

export default UserList
