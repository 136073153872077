import React from 'react'
import { NavLink } from 'react-router-dom'
import './AdminsNavigation.css'
import logo from '../../images/small_logo2.png';

const AdminsNavigation = () => {
  return (
    <div className='nav-container'>
      <div className='nav'>
        <div className='logo_name'>
          <p>Online DP</p>
        </div>

        <div className='spacerGray'></div>
        <NavLink to='/admin-list'>Admins</NavLink>
        {/* to what path to go */}
        <NavLink to='/mentor-list'>Mentors</NavLink>
        <NavLink to='/instructor-list'>Coaches</NavLink>
        <NavLink to='/encourager-list'>Encouragers</NavLink>
        <NavLink to='/student-list' className={'students'}> Students </NavLink>
        <div className='spacerGray'></div>
        {/* <div className='spacerGray spacerGrayBorder'></div> */}
        <NavLink to='/scriber-list' className={'scribers'}> Writers </NavLink>
        <NavLink to='/moderator-list'>Moderators</NavLink>
        <div className='spacerGray'></div>
        <NavLink to='/contact-us'>Feedbacks</NavLink>
        <div className='spacerGray'></div>
      </div>
    </div>
  )
}

export default AdminsNavigation
