import { BrowserRouter, Routes, Route } from 'react-router-dom'

import './App.css'
import PersistLogin from './config/PersistLogin'
import RequireAuth from './config/RequireAuth'
import SignUpWindow from './components/SignUpWindow'
import PageAdmin from './pages/adminPages/PageAdmin'
import Layout from './pages/adminPages/Layout'
import Home from './pages/Home'
import PageMentor from './pages/adminPages/PageMentor'
import PageInstructor from './pages/adminPages/PageInstructor'
import PageEncourager from './pages/adminPages/PageEncourager'
import PageStudent from './pages/adminPages/PageStudent'
import PageModerator from './pages/adminPages/PageModerator'
import PageScriber from './pages/adminPages/PageScriber'
import PageFeedbacks from './pages/adminPages/PageFeedbacks'

// import ChatShablon from './components/ChatShablon'

const App = () => {
  return (
    <div className='App'>
      <Routes>
        {/* all public users/viewers */}
        <Route element={<Home />} path='/' />
        <Route element={<SignUpWindow />} path='/signup/:id' />
        {/* New SignUp route */}
        {/* user role: admin */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[3333]} />}>
            <Route element={<Layout />}>
              <Route element={<PageAdmin />} path='/admin-list' />
              <Route element={<PageMentor />} path='/mentor-list' />
              <Route element={<PageInstructor />} path='/instructor-list' />
              <Route element={<PageEncourager />} path='/encourager-list' />
              <Route element={<PageScriber />} path='/scriber-list' />
              <Route element={<PageModerator />} path='/moderator-list' />
              <Route element={<PageStudent />} path='/student-list' />
              <Route element={<PageFeedbacks />} path='/contact-us' />
            </Route>
          </Route>
        </Route>
      </Routes>
    </div>
  )
}

export default App
