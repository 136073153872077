import React, { useState, useEffect } from 'react';
// import { differenceInYears, differenceInDays } from 'date-fns';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
// import useAuth from '../hooks/useAuth';

import './fromContactUs.css';
import checked from '../pages/adminPages/images/checked.png';
import checked_not from '../pages/adminPages/images/checked_not.png';
import del_btn from '../pages/adminPages/images/del_btn.png';

const FromContactUs = () => {
  const [contactUsNote, setContactUsNote] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  // const { auth } = useAuth();


  useEffect(() => {
    const fetchContactUsNotes = async () => {
      try {
        const { data } = await axiosPrivate.get(`${process.env.REACT_APP_BACKEND_URL}/api/contactUs`);
        setContactUsNote(data);

      } catch (error) {
        console.error('Error fetching contactUs notes:', error);
      }
    };

    fetchContactUsNotes();
  }, [axiosPrivate]);


  const handleDelete = async (item) => {
    try {
      const confirmDelete = window.confirm('Are you sure you want to delete this item? The result will be visible after the refresh');

      if (confirmDelete) {
        await axiosPrivate.delete(`${process.env.REACT_APP_BACKEND_URL}/api/contactUs/${item}`);
        setContactUsNote((prevContactUsNote) => prevContactUsNote.filter((note) => note._id !== item));
      }
    } catch (error) {
      console.error('Error deleting note:', error);
      // Handle error
    }
  };


  const [newMessage, setNewMessage] = useState('')
  const [selectedCorrespondentId, setSelectedSenderId] = useState('')
  const [includeHisMessage, setIncludeHisMessage] = useState('')



  const sendMessage = async () => {
    try {
      if (newMessage.trim() !== '') {
        const trimmedMessage = newMessage.trim(); // trim extra spaces

        const payload = {
          receiver: selectedCorrespondentId,
          content: trimmedMessage,
          hisMessage: includeHisMessage,
        }

        // console.log('payload', payload)

        await axiosPrivate.post(`${process.env.REACT_APP_BACKEND_URL}/api/admin-messages/`, JSON.stringify(payload));
      }

    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <div className='from_contactUs'>
      <header>
        <p>From "Contact Us"  <span style={{ marginLeft: '20px', color: 'red' }}>(under construction)</span></p>
      </header>
      {contactUsNote.map((element, index) => (
        <main className='mapping_part' key={element._id} >
          <div className="left_wing">
            <div className='upper_part'>

              <div className='profile_picture'>
                <img src={element.informant.image} alt="" />
                <button className='reviews'>Reviews</button>
              </div>

              <div className='gegevens'>
                <div>
                  <p className='user_nick'> {element.informant.nick}</p>
                  <p className='searchName'> {element.informant.searchName} </p>
                </div>
                <div>
                  <p> {element.informant.occupation}</p>
                  <p> Security level: {element.informant.securityLevel} </p>
                  <p> DOB: {element.informant.birthDate} </p>
                  <p> {element.informant.location} </p>
                </div>
              </div>
            </div>

            <div className='lower_part'>
              <p>
                {element.informant.biography}
              </p>
            </div>


          </div>

          <div className="middle_part">
            <div className='his_message' >
              <div className="flex_it space_between">
                <p className='golden_heading'>His/her Message</p>
              </div>

              <p className='message_text'>
                {element.message}
              </p>
              <div className="spacer10px"></div>
              <p className='message_text'> Greetings,  </p>
              <p className='message_text'> {element.informant.nick}</p>
            </div>


            <textarea
              name="message"
              onChange={(e) => {
                setNewMessage(e.target.value);
              }}
              // id='message'
              cols="30" rows="1"
              placeholder='Type your reply'
            ></textarea>

          </div>
          <div className="right_wing">
            <div style={{ height: '100%' }}>
              <p className='golden_heading'>Admin notes</p>
              {element.adminNote ?
                <p className='notes'> {element.adminNote} </p> :
                <p className='notes'>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat inventore officia sed, fugit esse ratione deleniti corporis facere quia deserunt libero. Esse, debitis? fugit esse ratione deleniti corporis facere quia deserunt libero. Esse, debitis? fugit esse ratione deleniti corporis facere quia deserunt libero. Esse, debitis?
                </p>}
            </div>


            <div className='update_div'>
              <input
                type="text"
                name='adding_notes'
                // onKeyDown={(e) => {
                //   if (e.key === 'Enter') {
                //     addNote();
                //   }
                // }}
                placeholder='Type your notes here'
              />

              <button className='update-btn'
                onClick={() => {
                  setSelectedSenderId(element.informant._id);
                  setIncludeHisMessage(element.message)
                  sendMessage();
                }}
              >
                Update
              </button>
            </div>
          </div>

          <div className="btns">
            <div className='radio_btn'>
              <img src={checked ? checked : checked_not} alt="" />
            </div>
            <div className="delete_btn"
              title='remove this item from the DB'
              onClick={() => {
                handleDelete(element._id);
              }}
            >
              <img src={del_btn} alt="" />
            </div>
          </div>


        </main>

      ))}
      <div className='bottom_line'>
        <div className='end_session'>
          <p> &#8472; &#8472; end of the session &#8472; &#8472; </p>
        </div>
        <div className='end_session'>
          <p> &#8472; &#8472; end of the session &#8472; &#8472; </p>
        </div>
        <div className='end_session'>
          <p> &#8472; &#8472; end of the session &#8472; &#8472; </p>
        </div>
      </div>
    </div>
  );
};

export default FromContactUs;
