import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import './landerSession_HP.css'


const LandingPage = () => {

  return (
    <section className='lander_session_HP'>
      <div className='black_top_over_landerSession'></div>

      <div className='landing_part_HP'>

        <div className='parda0_HP'></div>
        <div className='ramka_HP'>
          <div className='parda_HP'></div>

          <div className='lander_flex_container_HP'>
            <article className='the_words_HP'>

              <div className='the_words_HP_subcontainer'>
                <div className='firstWord_HP'>
                  <p>TH<span>E</span> WAY</p>
                </div>

                {/* <div className='vertical_line_HP'>
                  <div className='circle_HP'></div>
                </div> */}
                <div className='secondWord_HP'>
                  <p>OF LOV<span>E</span></p>
                </div>

                {/* <div className='horizontal_line_HP'></div> */}
              </div>

              <main className='main_DT_HP'>
                <div>
                  <p>
                    No greater love than the one who shares his life with friends...
                  </p>
                </div>
              </main>
            </article>

            <article className='kitab_lamp_HP_container'>
              <div className='kitab_lamp_HP'></div>
            </article>
          </div>
        </div>

        <main className='main_MB_HP'>
          <div>
            <p>
              No greater love than the one who shares his life with friends...
            </p>
          </div>
        </main>

        <article className='plussign_and_ODP_container_HP'>
          <div className='plussign_HP'>
            <div> <p> 	&#65291; </p> </div>
          </div>

          <div className='ODP_sign_HP'
          // onClick={openSignUpWindow}
          >
            <button
              className='header_login_btn'

            >
              <p className='bu_menudagi_login'> OnDiP</p>
            </button>

            {/* {isPopupWindowOon && <SignUpWindow />} */}
          </div>
        </article>
      </div>
    </section>


  )
}

export default LandingPage
