import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import './SignUp.css'

import SignupSuccessPopup from './SignupSuccessPopup'

const SignUp = () => {
  const navigate = useNavigate()

  const goBack = () => {
    navigate('/')
  }

  const params = useParams()

  useEffect(() => {
    const checkId = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/signup/admin-id-verification/${params.id}`
        )
        // console.log('data', data)
      } catch (error) {
        navigate('/unauthorized')
      }
    }

    checkId()
  }, [])

  const [formState, setFormState] = useState({
    username: '',
    password: '',
    confirmPassword: '',
    nick: '',
    searchName: '',
    occupation: '',
    location: '',
    birthDate: '',
    _id: params.id,
  })

  useEffect(() => {
    // console.log('formState', formState)
  }, [formState])

  // Old one
  // const changeHandler = (e) => {
  //   setFormState({
  //     ...formState,
  //     [e.target.name]: e.target.value,
  //   })
  // }

  const changeHandler = (e) => {
    const { name, value } = e.target

    // If the input is for searchName and "@" is not already present, add it
    const updatedValue =
      name === 'searchName' && !value.includes('@') ? `@${value}` : value

    setFormState({
      ...formState,
      [name]: updatedValue,
    })
  }

  const isValid =
    Boolean(formState.username) &&
    Boolean(formState.password) &&
    Boolean(formState.confirmPassword) &&
    Boolean(formState.searchName) &&
    Boolean(formState.occupation) &&
    Boolean(formState.location) &&
    Boolean(formState.birthDate) &&
    Boolean(formState.nick)

  const [passwordError, setPasswordError] = useState(null)
  const [error, setError] = useState(null)

  const [showSuccessPopup, setShowSuccessPopup] = useState(false)

  const handleSignup = async (e) => {
    e.preventDefault()

    if (formState.password !== formState.confirmPassword) {
      setPasswordError('Your two password fields do not match.')
      return
    }

    if (!isValid) {
      setError('Please fill in all the mandatory fields.')
      return
    }

    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/signup/admin-completion`,
        formState
      )
      // console.log(data)
      setShowSuccessPopup(true)
    } catch (error) {
      if (error.response?.status === 409 || error.response?.status === 400) {
        setError(error.response.data.message)
      } else {
        setError("An unexpected error occurred. Please try again later.");
      }
    }
  }

  const clearPasswordError = () => {
    if (formState.password === formState.confirmPassword) {
      setPasswordError(null)
    }
  }

  useEffect(() => {
    // console.log('formState', formState)
  }, [formState])

  return (
    <div className='sign_up_korobka2'>
      <div className='sign_up'>
        <div className='sign_up_subcontainer'>
          <main className=''>
            <p className='sign_up_LF'>Admins Sign Up</p>
            <button className='login-form-close' onClick={goBack}>
              &times;
            </button>

            <div className='form-container'>
              <form onSubmit={handleSignup} autoComplete='off'>
                <div className='flexed'>
                  <div className='user-box user_ism'>
                    <input
                      type='text'
                      name='username'
                      value={formState.username}
                      onChange={changeHandler}
                      placeholder='You need this only for login'
                    />
                    <label htmlFor='username'>User Name:</label>
                  </div>

                  <div className='user-box user_ism'>
                    <input
                      type='text'
                      name='nick'
                      value={formState.nick}
                      onChange={changeHandler}
                      placeholder='You do not have to put your real name'
                    />
                    <label htmlFor='nick'>Full Name:</label>
                  </div>
                </div>

                <div className='flexed'>
                  <div className='password user-box'>
                    <input
                      type='password'
                      name='password'
                      value={formState.password}
                      onChange={changeHandler}
                      onBlur={clearPasswordError}
                    />
                    <label htmlFor=''>Password:</label>
                  </div>

                  <div className='password confirmPassword user-box'>
                    <input
                      type='password'
                      name='confirmPassword'
                      value={formState.confirmPassword}
                      onChange={changeHandler}
                      onBlur={clearPasswordError}
                    />
                    <label htmlFor=''>confirm password:</label>
                  </div>
                </div>

                <div className='flexed'>
                  <div className='user-box user_ism'>
                    <input
                      type='text'
                      name='searchName'
                      value={formState.searchName}
                      onChange={changeHandler}
                      placeholder='@ necessary for the search'
                    />
                    <label htmlFor='username'>Search Name:</label>
                  </div>
                  <div className='user-box user_ism'>
                    <input
                      id='date_of_birth'
                      type='date'
                      name='birthDate'
                      value={formState.birthDate}
                      onChange={changeHandler}
                    />
                    <label htmlFor='nick'>Date of Birth:</label>
                  </div>
                </div>
                <div className='flexed'>
                  <div className='user-box user_ism'>
                    <input
                      type='text'
                      name='occupation'
                      value={formState.occupation}
                      onChange={changeHandler}
                      placeholder='what do u do for a living?'
                    />
                    <label htmlFor='username'>Occupation:</label>
                  </div>

                  <div className='user-box user_ism'>
                    <input
                      type='text'
                      name='location'
                      value={formState.location}
                      onChange={changeHandler}
                      placeholder='you can put the name of your region'
                    />
                    <label htmlFor='nick'>Location:</label>
                  </div>
                </div>

                <div className='flexed'>
                  <button
                    className='submit-btn'
                    type='submit'
                  // disabled={!isValid}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    Submit
                  </button>

                  <div className='error-message'>
                    {passwordError && <p> {passwordError}</p>}
                    {error && <p> {error} </p>}
                  </div>
                </div>
              </form>
            </div>
          </main>
        </div>
        {showSuccessPopup && (
          <SignupSuccessPopup
            onClose={() => setShowSuccessPopup(false)}
            navigate={navigate}
          />
        )}
      </div>
    </div>
  )
}

export default SignUp
