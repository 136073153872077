import React from 'react';

const SignupSuccessPopup = ({ onClose, navigate }) => {

  const closeAndNavigate = () => {
    onClose();
    navigate('/');
  };

  return (
    <div className='success-popup-contain'>
      <div className='success-popup'>
        <h3>You successfully signed in!</h3>
        <div className="spacer20px"></div>
        <p>Do not forget your user name and password!</p>
        <div className='with_border'>
          <p>Now, after closing this dialogue box (top right button),
            you can click "Sign in" button
          </p>
        </div>
        <p>(which is also on the top right corner).</p>

        <button onClick={closeAndNavigate}>Close</button>
      </div>
    </div>
  );
};

export default SignupSuccessPopup;