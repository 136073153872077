import React from 'react'

import Header from './HPforPublic/HPpages/Header';
import LanderSession_HP from './HPforPublic/HPpages/LanderSession_HP';


const Home = () => {
  return (
    <>

      <Header />
      <LanderSession_HP />
    </>
  )
}

export default Home;