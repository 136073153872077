import React, { useEffect, useState } from 'react'
import './InviteUser.css'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

function InviteUser() {
  const axiosPrivate = useAxiosPrivate()

  const [inviteDetails, setInviteDetails] = useState({
    role: '',
    email: '',
    whatsapp: '',
  })

  const [error, setError] = useState('')
  const [goodNews, setGoodNews] = useState('')

  const userRoleOptions = [
    'student',
    'admin',
    'mentor',
    'coach',
    'encourager',
    'writer',
    'moderator',
  ]

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (isValid) {
      try {
        await axiosPrivate.post(
          `${process.env.REACT_APP_BACKEND_URL}/user-notifier/invitation`,
          JSON.stringify(inviteDetails)
        )

        setGoodNews('Fantastic: the invitation is sent!')
        setTimeout(() => {
          setGoodNews('');
        }, 2000);

        setTimeout(() => {
          setInvitationPopOn(false);
        }, 2000);

      } catch (error) {
        setError(error)
      }
    } else {
      setError('Choose a role and then enter his email OR whatsapp.')
      setTimeout(() => {
        setError('');
      }, 3000);
    }
  }

  const handleChange = (e) => {
    setInviteDetails({
      ...inviteDetails,
      [e.target.name]: e.target.value,
    })
  }

  useEffect(() => {
  }, [inviteDetails])

  useEffect(() => {
    setError('')
  }, [inviteDetails.email, inviteDetails.role, inviteDetails.whatsapp])

  const isValid =
    Boolean(inviteDetails.role) &&
    (Boolean(inviteDetails.email) || Boolean(inviteDetails.whatsapp))

  const [isInvitationPopOn, setInvitationPopOn] = useState(false)

  return (
    <>
      <button
        title='Add someone'
        className='add-someone-in-invite-form'
        onClick={() => {
          setInvitationPopOn(true)
        }}
      >
        invite a new person
      </button>

      {isInvitationPopOn && (
        <div className='invitation_case_contain'>

          <main className='invitation_case'>
            <button
              className='close_invitation'
              onClick={() => {
                setInvitationPopOn(false)
                setInviteDetails({
                  role: '',
                  email: '',
                  whatsapp: '',
                })
                setGoodNews('')
              }}
            >
              &times;
            </button>

            <form onSubmit={handleSubmit} autoComplete='off'>
              <h2 className='heading'>In<span>v</span>it<span>e</span> a n<span>e</span>w p<span>e</span>rson</h2>

              <div className='flex_it'>
                <label htmlFor='role'>to the fellowship as a(n)</label>
                <select onChange={handleChange} name='role' id='role'>
                  <option value=''>--select a role --</option>
                  {userRoleOptions.map((role, index) => {
                    return (
                      <option key={index} value={role}>
                        {role}
                      </option>
                    )
                  })}
                </select>
              </div>


              <div className="spacer30px"></div>
              <label htmlFor='email'>Enter his email:</label>
              <input
                type='email'
                name='email'
                id='email'
                value={inviteDetails.email}
                onChange={handleChange}
              />

              <label htmlFor='whatsapp'>or his whatsapp:</label>
              <input
                type='text'
                name='whatsapp'
                id='whatsapp'
                value={inviteDetails.whatsapp}
                onChange={handleChange}
              />

              <div className="spacer10px"></div>
              <div className="flexed">
                <button className='submit-btn' type='submit' >
                  SEND
                </button>

                {error && <p className='error'>{error}</p>}
                {goodNews && <p className='goodNews'>{goodNews}</p>}
              </div>
            </form>
          </main>
        </div>
      )}
    </>
  )
}

export default InviteUser
