import React, { useState, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import AdminsNavigation from './AdminsNavigation'
import './Layout.css'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { differenceInYears } from 'date-fns';
import InviteUser from './InviteUser'
import SearchContainer from '../../components/SearchContainer'
import Profile from '../../components/Profile'
import useAuth from '../../hooks/useAuth'

// import avatar from '../../images/abeke.png'

const Layout = () => {

  const axiosPrivate = useAxiosPrivate()
  const { auth, setAuth } = useAuth(); //currently logged admin
  const navigate = useNavigate()


  const [search, setSearch] = useState('')

  // console.log("search", search)

  const [users, setUsers] = useState([])
  const [error, setError] = useState('')

  const [counter, setCounter] = useState(0);
  const triggerRerenderProfiles = () => {
    setCounter(counter + 1);
    // console.log("counter1 for updateChanges", counter);
  };


  useEffect(() => {
    const fetchUser = async () => {
      if (search) {
        try {
          // console.log("runs?")
          const { data } = await axiosPrivate.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/users?name=${search}`
          )
          setUsers(data)
        } catch (error) {
          setError(error)
        }
      }
    }

    fetchUser()
  }, [search])


  const [selectedUserId, setSelectedUserId] = useState('')
  const [isPopupWindowOnn, setPopupWindowOnn] = useState(false)

  const selectedUser = users.find((user) => user._id === selectedUserId);

  const handleLogout = async () => {
    navigate('/')
    setAuth({})
    // create endpoint to handle two things:
    // deletes refreshToken from DB
    // erases refreshToken from httpOnly cookie
    try {
      await axiosPrivate(`${process.env.REACT_APP_BACKEND_URL}/logout`, {
        withCredentials: true,
      })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <div className='admins_layout_container'>
        <div className='admins_subcontainer'>
          <main className='admins_layout'>
            <AdminsNavigation />
            <div className='admins_outlet'>
              <aside>

                <div className='logo_and_search'>
                  <SearchContainer setSearch={setSearch} />
                </div>

                <InviteUser />

                <div className='header_btn_container'>
                  <div className='currently_logged'>
                    <p> <span>Logged as:</span> {auth.nick}</p>
                  </div>
                  <button onClick={handleLogout} title='See you later!' className='admin_Log_out'>
                    Log out
                  </button>
                </div>
              </aside>

              <div className={`search_results ${(!search) ? 'no-content' : ''}`}>
                <div className={`s_results_subcontainer ${(!search) ? 'no-content' : ''}`} >

                  {search && users?.map((element, index) => {
                    const age = differenceInYears(new Date(), new Date(element.birthDate));

                    return (
                      <>
                        <div className='click_to_Profile'
                          key={element._id}
                          title='click to see the profile'
                          onClick={() => {
                            setPopupWindowOnn(true);
                            setSelectedUserId(element._id);
                          }}
                        >
                          <div className='dashboard-ordinals'>
                            <div>
                              {index + 1}
                            </div>
                          </div>
                          <div className='dashboard-names'>{element.nick} </div>
                          <div className='dashboard-age'>{age} y.o.</div>
                          <div className='dashboard-location'> {element.location}</div>
                          <div className='dashboard-occupation'> {element.occupation}</div>
                          <div className='dashboard-level'> Level: {element.securityLevel}</div>
                        </div>
                      </>
                    )
                  }
                  )}
                </div>
              </div>
              <Outlet />

            </div>
          </main>
        </div>
      </div>

      {isPopupWindowOnn && selectedUser && (
        <div className='profile-case-container'>
          <main className=''>
            <div className='all_kind_of_buttons'>
              <div className="for_click"
                onClick={() => setPopupWindowOnn(false)}
              ></div>
              <button
                title='close this profile case'
                className='close_profile_case'
                onClick={() => setPopupWindowOnn(false)}
              >
                &times;
              </button>
            </div>

            <Profile
              setPopupWindowOnn={setPopupWindowOnn}
              selectedUserId={selectedUserId}
              onUpdateChangesSuccess={triggerRerenderProfiles}
              onSuccessfulDelete={triggerRerenderProfiles}
            />
          </main>


        </div>
      )}
    </>
  )
}

export default Layout
