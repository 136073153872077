import React, { useState, useEffect } from 'react'
import './Search.css'

const SearchContainer = ({ setSearch }) => {

  const [inputClassName, setInputClassName] = useState('');

  return (
    <>
      <div className='sidebar_search'>
        <form>
          {/* <input
            type='search'
            onChange={(e) => setSearch(e.target.value)}
            placeholder='Search...'
          /> */}

          <input
            type='search'
            onChange={(e) => {
              setSearch(e.target.value);
              // Check if something is typed and apply className accordingly
              if (e.target.value.trim() !== '') {
                setInputClassName('typed-input');
              } else {
                // Reset the className when input is empty
                setInputClassName('');
              }
            }}
            placeholder='Search...'
            className={inputClassName} // Apply the className dynamically
          />


          <div className='search_unicode'>&#9906;</div>
        </form>
      </div>
    </>
  )
}

export default SearchContainer
