import React from 'react'
import UserList from '../../components/UserList'



const PageModerator = () => {
  return <UserList role='moderator' />
}

export default PageModerator
