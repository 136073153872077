import React, { useState, useRef, useEffect } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import './MessageCreate.css';
import useAuth from '../hooks/useAuth';

const MessageCreate = ({ titleNomi, className, recipient, recipientEmail }) => {

  const { auth } = useAuth(); //currently logged admin


  const [isPopupWindOnPost, setPopupWindOnPost] = useState(false);

  const textareaRef = useRef(null);
  const recipientDivRef = useRef(null);
  const senderInfoRef = useRef(null);

  const axiosPrivate = useAxiosPrivate()

  const [error, setError] = useState('')
  const [goodNews, setGoodNews] = useState('')

  const submitMessage = async (e) => {
    e.preventDefault()

    const messageContent = textareaRef.current.value;
    const senderInfo = senderInfoRef.current.innerText;

    const recipientContent = recipientDivRef.current.innerText;
    const messageInfo = {
      recipientEmail,
      recipientContent,
      messageContent,
      senderInfo,
    };

    const isValid = Boolean(messageContent)

    if (isValid) {
      try {
        await axiosPrivate.post(
          `${process.env.REACT_APP_BACKEND_URL}/user-notifier/message`,
          JSON.stringify(messageInfo)
        )
        setGoodNews('Fantastic: the message is sent!')


      } catch (error) {
        setError(error)
      }
    } else {
      setError('Did you put content in your message?')
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setPopupWindOnPost(false)
      }
    };

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    };
  }, [setPopupWindOnPost])

  return (
    <>
      <button
        title="compose a message"
        className={className}
        onClick={() => {
          setPopupWindOnPost(true);
        }}
      >
        {titleNomi}
      </button>

      {isPopupWindOnPost && (
        <div className='message_create_container' id='message_create_container'>
          <div className='message_create' id='message_create'  >
            <header className='flex_it'>
              <button
                title='try esc key also'
                className='close_new_message'
                onClick={() => {
                  setPopupWindOnPost(false);
                }}
              >
                {' '}
                &times;
              </button>

              <div className='titul'>
                <h4>   This message will be sent  </h4>
                <p>to: {recipientEmail} </p>
              </div>
            </header>
            <main>
              <div className='container'>
                <div className='recipient' ref={recipientDivRef}>
                  <p>Dear {recipient},</p>
                </div>
                <textarea
                  ref={textareaRef}
                  name=""
                  id=""
                  cols="30"
                  rows="8"
                  placeholder='(Write your message here)'
                ></textarea>
                <div >
                  <p>with respect,</p>
                  <p ref={senderInfoRef}>{auth?.nick}</p>
                </div>
              </div>

              <div className='flex_it'>
                <button
                  className="send_btn"
                  onClick={submitMessage}
                  title='send this message'
                >
                  Send
                </button>

                <div className='for_success_and_errors'>
                  {error && <p className='error'>{error}</p>}
                  {goodNews && <p className='goodNews'>{goodNews}</p>}
                </div>
              </div>
            </main>
          </div>
        </div>
      )}
    </>
  );
}

export default MessageCreate;
