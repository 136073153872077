import React from 'react'
import UserList from '../../components/UserList'



const PageEncourager = () => {

  return <UserList role='encourager' />

}

export default PageEncourager
