import React, { useState, useEffect } from 'react'
import UserList from '../../components/UserList'

const PageAdmin = () => {



  return (
    <UserList
      role='admin'
    />
  )
}

export default PageAdmin
