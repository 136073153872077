import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
// import './reviewCreate.css';
import './AddReview.css';

import useAuth from '../hooks/useAuth';

const AddReview = ({ onNewReviewPostSuccess, titleNomi, className, currentUser }) => {

  const { auth } = useAuth()

  const axios = useAxiosPrivate()
  const [error, setError] = useState('')
  const [goodNews, setGoodNews] = useState('')

  const [isPopupReviewOn, setPopupReviewOn] = useState(false);

  const [content, setContent] = useState('')

  const submitReview = async (e) => {
    e.preventDefault()

    const objectToSend = {
      author: auth?.userId,
      referencedUser: currentUser._id,
      content
    }

    const isValid = Boolean(content)

    if (isValid) {
      try {
        await axios.post(
          'http://localhost:5500/api/reviews/',
          JSON.stringify(objectToSend)
        )
        setGoodNews('Fantastic: the review is posted!')

        setTimeout(() => {
          setPopupReviewOn(false);
        }, 4000);

        onNewReviewPostSuccess();

      } catch (error) {
        setError(error)
      }
    } else {
      setError('Did you put content in your review?')
    }

  };



  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setPopupReviewOn(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      <button
        title="compose a review"
        className={className}
        onClick={() => {
          setPopupReviewOn(true);
        }}
      >
        {titleNomi}
      </button>

      {isPopupReviewOn && (
        <div className='review_create_container'>
          <div className='review_create'    >
            <header className='flex_it'>
              <button
                title='try esc key also'
                className='close_new_review'
                onClick={() => {
                  setPopupReviewOn(false);
                }}
              >
                {' '}
                &times;
              </button>

              <div className='titul'>
                <h4>   This review will be posted   </h4>
                <p>on the profile of: <span>{currentUser?.nick}</span></p>
              </div>
            </header>
            <main>
              <div className='container'>
                <div className='recipient'>

                </div>
                <textarea
                  onChange={e => setContent(e.target.value)}
                  value={content}
                  cols="30"
                  rows="8"
                  placeholder='Write your review here'
                ></textarea>
                <div>
                  <p>The review is posted by:</p>
                  <p>{auth?.nick}</p>
                </div>
              </div>

              <div className='flex_it'>
                <button
                  className="post_review_btn"
                  onClick={submitReview}
                  title='post your review'
                >
                  Post
                </button>

                <div className='for_success_and_errors'>
                  {error && <p className='error'>{error}</p>}
                  {goodNews && <p className='goodNews'>{goodNews}</p>}
                </div>
              </div>
            </main>
          </div>
        </div>
      )}
    </>
  );
}

export default AddReview;
