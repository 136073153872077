import React from 'react'
import UserList from '../../components/UserList'



const PageInstructor = () => {
  return <UserList role='coach' />
}

export default PageInstructor
