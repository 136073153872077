import React from 'react'
import FromContactUs from '../../components/FromContactUs';
import FromAskSupport from '../../components/FromAskSupport';
import './pageFeedbacks.css'

const PageFeedbacks = ({ userIdWithFeedback }) => {


  return (
    <>
      <div className="feedback_page">
        <FromContactUs userIdWithFeedback={userIdWithFeedback} />
        {/* <FromAskSupport /> */}
      </div>
    </>
  )
}

export default PageFeedbacks