import React, { useState, useEffect } from 'react'
import bin from '../pages/HPforPublic/HPimages/bin.png'
import "./Warnung.css"
import useAxiosPrivate from '../hooks/useAxiosPrivate'

const Warnung = ({ selectedUserId, setPopupWindowOnn, onSuccessfulDelete }) => {

  const [isPopupWarnungOn, setPopupWarnungOn,] = useState(false)

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setPopupWarnungOn(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };


  }, []);

  const axiosPrivate = useAxiosPrivate()

  const [error, setError] = useState('')
  const [sadNews, setSadNews] = useState('')

  const handleDeleteUser = async () => {
    try {
      await axiosPrivate.delete(`${process.env.REACT_APP_BACKEND_URL}/api/users/${selectedUserId}`)
      setSadNews('Sadly, this user no longer exists in ODP!')

      setTimeout(() => {
        onSuccessfulDelete();
      }, 4000);

      setTimeout(() => {
        setPopupWindowOnn(false);
      }, 4000);

    } catch (error) {
      setError('Something went wrong: maybe server is down?')
    }
  }

  const [hoverCount, setHoverCount] = useState(0);

  const handleMouseEnter = () => {
    setHoverCount((prevCount) => prevCount + 1);
  };

  const getTransformValue = () => {
    if (hoverCount === 1) {
      return 'translateX(150px)';
      // } else if (hoverCount === 2) {
      //   return 'translateX(150px)';
    } else {
      return 'translateX(0)';
    }
  };



  return (
    <>
      <button
        title='delete this user'
        className='delete_this_user'
        onClick={() => {
          setPopupWarnungOn(true)
        }}
      >
        Delete
      </button>

      {
        isPopupWarnungOn && (
          <div className='warnung_outer_contain' >
            <main className='warnung_case'
            >
              <div className="close-warnung">
                <button
                  title='hide this dialogue box'
                  className='close_it'
                  onClick={() => setPopupWarnungOn(false)}
                >
                  &times;
                </button>
              </div>

              <div className='Warning-dialogue-box-subcontainer'>

                <div className='Warning-dialogue-box'>
                  <div className='are_u_sure_to_remove'>
                    <h3 >Are you sure to delete</h3>
                    <h3> this user?</h3>
                  </div>

                  <button className='red-btn moveable-component'
                    style={{ transform: getTransformValue() }}
                    onClick={handleDeleteUser}
                    onMouseEnter={handleMouseEnter}
                  >
                    yes, sorry
                  </button>

                  <button className='no-btn'
                    onClick={() => setPopupWarnungOn(false)}>oops, no</button>

                  <div className="include_publications">
                    <div className='radio-label-container'>

                      <div className='radio-label'>
                        <input
                          type='radio'
                          name='timeSlot'
                          id='keep_it'
                          value='keep_it'
                        />
                        <label className='morgen' htmlFor='keep_it'>
                          keep the publications?</label>
                      </div>



                      <div className='radio-label'>
                        <input
                          type='radio'
                          name='timeSlot'
                          id='dont_keep_it'
                          value='dont_keep_it'
                        />

                        <label htmlFor='dont_keep_it'> or remove? </label>
                      </div>
                    </div>
                  </div>

                  {error && <div className='error'>
                    <div>
                      <div>
                        <p className='bigger_font'> {error}</p>
                        <p className='smaller_font'>Try it again later.</p>
                      </div>
                    </div>
                  </div>}
                  {sadNews && <div className='sadNews'>
                    <div>
                      <div>
                        <p className='bigger_font'> {sadNews}</p>
                        <p className='smaller_font'>The change will be visible after the refresh.</p>
                      </div>
                    </div>
                  </div>}

                </div>
              </div>


            </main>
          </div>
        )
      }

    </>
  )
}

export default Warnung