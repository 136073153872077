import React from 'react';
import './Section.css';

const Section = () => {
  return (

    <main className='photos'>


      <section className="content">
        <div className="left-content">
          <div className="activities">

            <div className="activity-container">
              <div className="image-container img-one">
                <img src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/467cf682-03fb-4fae-b129-5d4f5db304dd" alt="tennis" />
                <div className="overlay">
                  <h3>24 04 2023</h3>
                </div>
              </div>

              <div className="image-container img-two">
                <img src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/3bab6a71-c842-4a50-9fed-b4ce650cb478" alt="hiking" />
                <div className="overlay">
                  <h3>14 07 2020</h3>
                </div>
              </div>

              <div className="image-container img-three">
                <img src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/c8e88356-8df5-4ac5-9e1f-5b9e99685021" alt="running" />
                <div className="overlay">
                  <h3>06 09 2019</h3>
                </div>
              </div>

              <div className="image-container img-four">
                <img src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/69437d08-f203-4905-8cf5-05411cc28c19" alt="cycling" />
                <div className="overlay">
                  <h3>15 05 2024</h3>
                </div>
              </div>

              <div className="image-container img-five">
                <img src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/e1a66078-1927-4828-b793-15c403d06411" alt="yoga" />
                <div className="overlay">
                  <h3>30 06 2021</h3>
                </div>
              </div>

              <div className="image-container img-six">
                <img src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/7568e0ff-edb5-43dd-bff5-aed405fc32d9" alt="swimming" />
                <div className="overlay">
                  <h3>28 04 2024</h3>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </main>

  );
};

export default Section;
