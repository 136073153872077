
import './header.css'
// import './aside_HP.css'
import React, { useState, useEffect } from 'react'
import LoginDialogueBox from './LoginDialogueBox'


const Header = () => {
  // const [isAsideExpanded, setAsideExpanded] = useState(false)

  return (
    <div className='header'>
      <div className='nav_container_HP'>
        <nav className="navbar_HP">
          <div className="logo_topleft_corner"></div>
          <div className="navLink_container_HP">
            {/* <li className="navLink_HP" to="#ishimiz_haqida">Objective</li> */}
            <li className="navLink_HP" to="#biz_kimmiz">Welcome to the fellowship of Admins</li>
            {/* <li className="navLink_HP" to="#kontakt_qismi">Contact</li> */}
          </div>
          <div className='topBar_center_HP'> </div>
          <div>
            <LoginDialogueBox />
          </div>
        </nav>
      </div>


      {/* <aside className={isAsideExpanded ? 'menu_activated' : ''}>
        <nav className="side_nav">
          <div className="room"></div>
          <ul>
            <li> Boş sahifa</li>
            <li> Dahobirla qayerga</li>
            <li> Obodonchilik</li>
            <li> Pispadon suhbatlardan</li>
            <li> Bir kuni</li>
            <div className="line_for_tillar"></div>
            <li className="tillar"><a id="tillar_a" href="">Languages</a>

              <ul className="birinchi_qator_ul">
                <li><a>En</a></li>
                <li><a>NL</a></li>
                <li><a href="./index.html">Óz</a></li>
              </ul>
              <ul>
                <li><a href="./Uzbek_Cyr.html">Ўз</a></li>
                <li><a href="./Russian.html">Ru</a></li>
                <li><a>Tk</a></li>
              </ul>
            </li>
          </ul>
        </nav>
      </aside> */}
    </div>
  )
}


export default Header
