import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate, useLocation } from 'react-router-dom'
import './login-dialogue-box.css'
import useAuth from '../../../hooks/useAuth'

const LoginDialogueBox = () => {
  const { setAuth, persist, setPersist } = useAuth()

  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/admin-list'

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const [errMsg, setErrMsg] = useState('')

  useEffect(() => {
    setErrMsg('')
  }, [username, password])

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/auth`,
        JSON.stringify({ username, password }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      )

      // console.log('response', response)

      const accessToken = response?.data?.accessToken
      const role = response?.data?.role
      const userId = response?.data?.userId
      const nick = response?.data?.nick
      const searchName = response?.data?.searchName
      const createdAt = response?.data?.createdAt
      const email = response?.data?.email

      setAuth({
        userId,
        nick,
        searchName,
        username,
        createdAt,
        role,
        accessToken,
        email
      })
      setUsername('')
      setPassword('')
      navigate(from, { replace: true }) // we don't want to store in history this page on back button click
    } catch (error) {
      if (!error?.response) {
        setErrMsg('No server response')
      } else if (
        error.response?.status === 400 ||
        error.response?.status === 401 ||
        error.response?.status === 403 ||
        error.response?.status === 404
      ) {
        setErrMsg(error.response.data.message)
      } else {
        setErrMsg('Login failed')
      }
    }
  }

  const togglePersist = () => {
    setPersist((prev) => !prev)
  }

  useEffect(() => {
    localStorage.setItem('persist', persist)
  }, [persist])

  const [isPopupWindowOn, setPopupWindowOn] = useState(false)
  const [backgroundOpacity, setBackgroundOpacity] = useState(0)

  return (
    <section className='authContainer'>
      {/* {
        errMsg ? (
          <div className='authErrorMessageContainer' >
            <p className='authErrorMessage'>{errMsg}</p>
          </div>
        ) : (
          <div className='authErrorMessageContainer'></div>
        )
      } */}

      <button
        className='header_login_btn'
        onClick={() => {
          setPopupWindowOn(true)
          setBackgroundOpacity(0.8)
        }}
      >
        <p className='bu_menudagi_login'>Sign in</p>
      </button>

      {isPopupWindowOn && (
        <div className='login_form_container'>
          <div
            className='parda01_HP'
            style={{ opacity: backgroundOpacity }}
            onClick={() => {
              setPopupWindowOn(false)
              setBackgroundOpacity(0.1)
            }}
          >
            {' '}
          </div>
          <div className='login-box-component'>
            <button
              className='login-close'
              onClick={() => setPopupWindowOn(false)}
            >
              {' '}
              &times;
            </button>
            <h2>Sign in</h2>
            <form
              className='authInputsContainer simple_form_to_login'
              onSubmit={handleSubmit}
            >
              <div className='user-box'>
                <input
                  id='username'
                  autoComplete='off'
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                  required
                  type='text'
                // placeholder='User name'
                />
                <label htmlFor='username'>Username</label>
              </div>
              <div className='user-box last_box'>
                <input
                  type='password'
                  id='password'
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  required
                // placeholder='Password'
                />
                <label htmlFor='password'>Password</label>
              </div>
              {errMsg ? (
                <div className='authErrorMessageContainer'>
                  <p className='authErrorMessage'>{errMsg}</p>
                </div>
              ) : (
                <div className='authErrorMessageContainer'>
                  <p>&#8203;</p>
                </div>
              )}
              <button className='authButton submit-btn'>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Send
              </button>
            </form>

            <div className='authPersistContainer trust_this_device'>
              <input
                type='checkbox'
                id='persist'
                onChange={togglePersist}
                checked={persist}
              />
              <label htmlFor='persist' style={{ verticalAlign: '2px' }}>
                Save my password on this device
              </label>
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

export default LoginDialogueBox
